.OTPscreen {
  background-color: white;
  color: black;
  text-align: center;
  display: flex;
  justify-content: center;
}
.otp {
  text-align: center;
  position: fixed;
  margin-top: 200px;
}
.otp-field {
  height: 35px;
  width: 45px;
  margin-left: 10px;
  margin-right: 20px;
  border: none;
  text-align: center;
  border-bottom: 2px solid #23a7df;
  padding: 5px 10px;
  outline: none;
}
.verifybtn {
  background-color: #23a7df;
  width: 280px;
  height: 45px;
  border-radius: 230px;
  color: white;
  border: none;
  transition: all 0.8s;
  margin-top: 30px;
}
.verifybtn:hover {
  background-color: #1682b1;
  transition: all 0.6s;
}
.resetBtn {
  border: none;
  background-color: #b99d9d1a;
  border-radius: 30px;
}
.resetBtnDiv {
  margin-top: 30px;
  margin-left: 250px;
}
.otherBtnDiv {
  margin-top: 30px;
}
.otherBtn {
  border: none;
  background-color: #b99d9d1a;
  border-radius: 30px;
}
.subTitle {
  color: #b9b9b9;
  margin-right: 8%;
}

@media screen and (max-width: 480px) {
  .OTPscreen {
    background-color: white;
    color: black;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .otp {
    text-align: center;
    position: absolute;
  }
  .otp-field {
    height: 35px;
    width: 45px;
    margin-left: 10px;
    margin-right: 20px;
    border: none;
    text-align: center;
    border-bottom: 2px solid #23a7df;
    padding: 5px 10px;
    outline: none;
  }
  .verifybtn {
    background-color: #23a7df;
    width: 280px;
    height: 45px;
    border-radius: 230px;
    color: white;
    border: none;
    transition: all 0.8s;
    margin-top: 30px;
  }
  .verifybtn:hover {
    background-color: #1682b1;
    transition: all 0.6s;
  }
  .resetBtn {
    border: none;
    background-color: #b99d9d1a;
    border-radius: 30px;
  }
  .resetBtnDiv {
    margin-top: 30px;
    margin-left: 250px;
  }
  .otherBtnDiv {
    margin-top: 30px;
  }
  .otherBtn {
    border: none;
    background-color: #b99d9d1a;
    border-radius: 30px;
  }
  .subTitle {
    color: #b9b9b9;
    margin-right: 8%;
  }
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .OTPscreen {
    background-color: white;
    color: black;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .otp {
    text-align: center;
    margin-top: 300px;
    position: absolute;
  }
  .otp-field {
    height: 35px;
    width: 45px;
    margin-left: 10px;
    margin-right: 20px;
    border: none;
    text-align: center;
    border-bottom: 2px solid #23a7df;
    padding: 5px 10px;
    outline: none;
  }
  .verifybtn {
    background-color: #23a7df;
    width: 280px;
    height: 45px;
    border-radius: 230px;
    color: white;
    border: none;
    transition: all 0.8s;
    margin-top: 30px;
  }
  .verifybtn:hover {
    background-color: #1682b1;
    transition: all 0.6s;
  }
  .resetBtn {
    border: none;
    background-color: #b99d9d1a;
    border-radius: 30px;
  }
  .resetBtnDiv {
    margin-top: 30px;
    margin-left: 250px;
  }
  .otherBtnDiv {
    margin-top: 30px;
  }
  .otherBtn {
    border: none;
    background-color: #b99d9d1a;
    border-radius: 30px;
  }
  .subTitle {
    color: #b9b9b9;
    margin-right: 8%;
  }
}
