.menulist {
  margin-top: 60px;
  width: 450px;
  height: 80px;
}
.menu-category {
  position: absolute;
  /* margin-left: 0; */
  margin-right: 1000px;
  font-size: 25px;
}
.addcartbtn {
  margin-left: -12px;
  margin-top: 90px;
  /* sx={{ width: 100, padding: 1, marginTop:5}} */
}
.menu-item-details {
  margin-top: -20px;
  font-size: small;
}
.menucard {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.item-img {
 
 
}
.item-img img {
  width: 100%;
  object-fit: cover;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  height: 500px;
}
.itemDetails {
  margin-top: 80px;
}
.itemDes{
  margin-left: 200px;
}
.itemDetails .item-title {
  font-weight: 500;
}
.itemDetails .item-desc-title {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 600;
}
.itemDetails .item-description {
  font-weight: 100;
  font-size: 12px;
}
.itemQnty{
  margin-left: 200px;
}

.item-quantity-title {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 600;
}
.item-quantity {
  margin-top: 20px;
  display: flex;
}
.item-quantity .item-count {
  font-size: 12px;
  font-weight: 600;
}
.item-quantity .btns {
  margin-left: 190px;
}
.item-quantity .btn-dec {
  background-color: white;
  border-color: #23A7DF;
  color: #23A7DF;
  border-bottom-left-radius: 16px;
  border-top-left-radius: 16px;
  padding-left: 14px;
  border-style: solid;
  
}
.item-quantity .btn-inc{
  
  background-color: #23A7DF;
  color: white;
  border-color: #23A7DF;
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px;
  border-style: solid;
  
}
.item-num{
  margin-left: 14px;
  background-color: white;
  border-color: #23A7DF;
  color: #23A7DF;
  border-style: solid;
  width: 50px;
  border-radius: 15px;
  text-align: center;
  
}
.item-extra{
  margin-left: 200px;
}

.item-extra-extra{
  margin-top: 20px;
  font-size: 14px;
  font-weight: 600;
}
.item-extra-list{
  
}

.addCartbtn{
  width: 100%;
  text-align: center;
  margin-top: 50px;
  height: 100px;
  
}

.itemAddCartBtn{
  
  border-radius: 30px;
  width: 340px;
  padding: 10px;
  background-color: #23A7DF;
  color: white;
  border: none;
}

@media screen and (max-width: 480px) {
  .menulist {
    margin-top: 60px;
    margin-right: 30px;
    width: 300px;
    height: 100px;
    margin-left: -73px;
  }
  .menu-category {
  }
  .addcartbtn {
    margin-left: -12px;
    margin-top: 90px;
    /* sx={{ width: 100, padding: 1, marginTop:5}} */
  }
  .menu-item-details {
    margin-top: -20px;
    font-size: small;
  }
  .menucard {
    position: absolute;
    display: flex;
    flex-direction: row;
    margin-left: 80px;
  }
  .item-img {
    width: 100%;
  }
  .item-img img {
    width: 100%;
    object-fit: cover;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    height: 300px;
  }
  .itemDes{
    margin:22px
  }
  .itemDetails {
   
  }
  .itemDetails .item-title {
    font-weight: 500;
  }
  .itemDetails .item-desc-title {
    margin-top: 20px;
    font-size: 14px;
    font-weight: 600;
  }
  .itemDetails .item-description {
    font-weight: 100;
    font-size: 12px;
  }
  .itemQnty{
    margin:22px
  }
  
  .item-quantity-title {
    margin-top: 20px;
    font-size: 14px;
    font-weight: 600;
  }
  .item-quantity {
    margin-top: 20px;
    display: flex;
  }
  .item-quantity .item-count {
    font-size: 12px;
    font-weight: 600;
  }
  .item-quantity .btns {
    margin-left: 190px;
  }
  .item-quantity .btn-dec {
    background-color: white;
    border-color: #23A7DF;
    color: #23A7DF;
    border-bottom-left-radius: 16px;
    border-top-left-radius: 16px;
    padding-left: 14px;
    border-style: solid;
    
  }
  .item-quantity .btn-inc{
    
    background-color: #23A7DF;
    color: white;
    border-color: #23A7DF;
    border-bottom-right-radius: 16px;
    border-top-right-radius: 16px;
    border-style: solid;
    
  }
 .item-num{
    margin-left: 14px;
    background-color: white;
    border-color: #23A7DF;
    color: #23A7DF;
    border-style: solid;
    width: 50px;
    border-radius: 15px;
    text-align: center;
    
  }
  .item-extra{
    margin:22px
  }
  .item-extra-extra{
    margin-top: 20px;
    font-size: 14px;
    font-weight: 600;
  }
  .item-extra-list{

  }

  .addCartbtn{
    width: 100%;
    text-align: center;
    margin-top: 70px;
  }

  .itemAddCartBtn{
    
    border-radius: 30px;
    width: 340px;
    padding: 10px;
    background-color: #23A7DF;
    color: white;
    border: none;
  }
}


/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .menulist {
    margin-top: 60px;
    width: 450px;
    height: 80px;
  }
  .menu-category {
    position: absolute;
    /* margin-left: 0; */
    margin-right: 1000px;
    font-size: 25px;
  }
  .addcartbtn {
    margin-left: -12px;
    margin-top: 90px;
    /* sx={{ width: 100, padding: 1, marginTop:5}} */
  }
  .menu-item-details {
    margin-top: -20px;
    font-size: small;
  }
  .menucard {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .item-img {
   
   
  }
  .item-img img {
    width: 100%;
    object-fit: cover;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    height: 500px;
  }
  .itemDetails {
    margin-top: 80px;
   
  }
  .itemDes{
    margin-left: 200px;
  }
  .itemDetails .item-title {
    font-weight: 500;
  }
  .itemDetails .item-desc-title {
    margin-top: 20px;
    font-size: 14px;
    font-weight: 600;
  }
  .itemDetails .item-description {
    font-weight: 100;
    font-size: 12px;
    width: 360px;
  }
  .itemQnty{
    margin-left: 200px;
  }
  
  .item-quantity-title {
    margin-top: 20px;
    font-size: 14px;
    font-weight: 600;
  }
  .item-quantity {
    margin-top: 20px;
    display: flex;
  }
  .item-quantity .item-count {
    font-size: 12px;
    font-weight: 600;
  }
  .item-quantity .btns {
    margin-left: 190px;
  }
  .item-quantity .btn-dec {
    background-color: white;
    border-color: #23A7DF;
    color: #23A7DF;
    border-bottom-left-radius: 16px;
    border-top-left-radius: 16px;
    padding-left: 14px;
    border-style: solid;
    
  }
  .item-quantity .btn-inc{
    
    background-color: #23A7DF;
    color: white;
    border-color: #23A7DF;
    border-bottom-right-radius: 16px;
    border-top-right-radius: 16px;
    border-style: solid;
    
  }
  .item-num{
    margin-left: 14px;
    background-color: white;
    border-color: #23A7DF;
    color: #23A7DF;
    border-style: solid;
    width: 50px;
    border-radius: 15px;
    text-align: center;
    
  }
  .item-extra{
    margin-left: 200px;
  }
  
  .item-extra-extra{
    margin-top: 20px;
    font-size: 14px;
    font-weight: 600;
  }
  .item-extra-list{
    
  }
  
  .addCartbtn{
    width: 100%;
    text-align: center;
    margin-top: 50px;
    height: 100px;
    
  }
  
  .itemAddCartBtn{
    
    border-radius: 30px;
    width: 340px;
    padding: 10px;
    background-color: #23A7DF;
    color: white;
    border: none;
  }
 
}
