.floatingBtn {
  background-color: #23a7df;
  height: 300px;
  color: white;
  display: flex;
  flex-direction: row;
  margin-top: 60px;
}
.totalAmt {
  margin-top: -150px;
  text-align: center;
  color: white;
}

@media screen and (max-width: 480px) {
  .floating {
    height: 30vh;
    background-color: #23a7df;
    margin-top: 200px;
  }
  .floatingBtn {
    color: white;
    /* marginLeft:200,marginTop:80, */
    margin-bottom: -100px;
  }
  .totalAmt {
    color: white;
    text-align: center;
  }
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .floating {
    height: 20vh;
    background-color: #23a7df;
    margin-top: 200px;
  }
  .floatingBtn {
    color: white;
    /* marginLeft:200,marginTop:80, */
    margin-bottom: -100px;
  }
  .totalAmt {
    color: white;
    text-align: center;
  }
}
