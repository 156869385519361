.header {
  background-color: white;
  height: 100vh;
  background-attachment: scroll;
}
.fdimageDiv {
}
.img {
  width: 100%;
  height: 60vh;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  object-fit: cover;
}
.card {
  position: absolute;
  top: -100px;
  margin-left: 30%;
  margin-right: 30%;
  /* margin-top: 29%; */
}
.card-content {
  height: 90px;
}
.search {
  height: 100vh;
  background-color: #2b87ae;
}
.search_bar {
  width: 100%;
  height: 300px;
  background-color: white;
  position: absolute;
  margin-top: 350px;
  text-align: center;
}
.search_form {
  margin-top: 20px;
  border: 0.2px solid;
  border-radius: 8px;
  border-color: rgb(216, 206, 206);
}
.search_i {
  position: absolute;
  margin-left: 680px;
  margin-top: 100px;
}
.title {
  font-size: 13px;
  font-weight: 600;
}
.search_input {
  margin-right: 10px;
  padding: 5px;
  border: none;
  font-size: small;
}
.enter_btn {
  margin-left: 230px;
  color: white;
  border: none;
  background-color: #2b87ae;
  width: 70px;

  border-radius: 4px;
  height: 32px;
}
.copywrite {
  text-align: center;
  position: absolute;
  margin-top: 900px;
  margin-left: 700px;
  margin-right: 30px;
  font-size: 12px;
  color: white;
}

@media screen and (max-width: 480px) {
  .header {
    background-color: white;
    height: 50vh;
    width: 100%;
  }
  .img {
    width: 100%;
    height: 400px;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    object-fit: cover;
  }
  .card {
    position: absolute;
    top: -80px;
    margin-left: 35px;
    margin-right: 50px;
    height: 150px;
    width: 350px;
  }
  .card-content {
    height: 90px;
  }
  .search {
    height: 100vh;
    background-color: #2b87ae;
  }
  .search_bar {
    width: 100%;
    height: 300px;
    background-color: white;
    position: absolute;
    margin-top: 290px;
    text-align: center;
  }
  .search_form {
    margin-top: 20px;
    border: 0.2px solid;
    border-radius: 8px;
    border-color: rgb(216, 206, 206);
  }
  .search_input {
    margin-right: 10px;
    padding: 5px;
    border: none;
    font-size: small;
  }
  .search_i {
    position: absolute;
    margin-top: 90px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .title {
    font-size: 13px;
    font-weight: 600;
  }
  .enter_btn {
    margin-left: 110px;
    color: white;
    border: none;
    background-color: #2b87ae;
    width: 70px;

    border-radius: 3px;
    height: 32px;
  }
  .copywrite {
    text-align: center;
    position: absolute;
    margin-top: 750px;
    margin-left: 30px;
    margin-right: 30px;
    font-size: 15px;
    color: white;
  }
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .header {
    background-color: white;
    height: 50vh;
    width: 100%;
  }
  .img {
    width: 100%;
    height: 400px;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    object-fit: cover;
  }
  .card {
    position: absolute;
    top: -80px;
    height: 150px;
    width: 500px;
    margin-left: 135px;
  }
  .card-content {
    height: 90px;
  }
  .search {
    height: 100vh;
    background-color: #2b87ae;
  }
  .search_bar {
    width: 100%;
    height: 300px;
    background-color: white;
    position: absolute;
    margin-top: 290px;
    text-align: center;
  }
  .search_form {
    margin-top: 20px;
    border: 0.2px solid;
    border-radius: 8px;
    border-color: rgb(216, 206, 206);
  }
  .search_input {
    margin-right: 10px;
    padding: 5px;
    border: none;
    font-size: small;
  }
  .search_i {
    position: absolute;
    margin-top: 90px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .title {
    font-size: 13px;
    font-weight: 600;
  }
  .enter_btn {
    margin-left: 110px;
    color: white;
    border: none;
    background-color: #2b87ae;
    width: 70px;

    border-radius: 3px;
    height: 32px;
  }
  .copywrite {
    text-align: center;
    position: absolute;
    margin-top: 750px;
    margin-left: 30px;
    margin-right: 30px;
    font-size: 15px;
    color: white;
  }
}
