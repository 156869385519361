.my_order_title {
  text-align: center;
  margin-top: 40px;
}
.order_card {
  margin-top: 20px;
  margin-left: 200px;
  margin-right: 200px;
  border-left: 10px solid #539a3f;
  
}
.order_main_content {
  margin-top: -20px;
}
.order_number {
}
.order_card_content_1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 13px;
}
.order_card_content_2 {
  height: 10px;
}
.order_detail_hide {
  text-align: center;
  margin-left: 300px;
  margin-right: 300px;
  background-color: rgb(208, 212, 212);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.order_detail_hide_div {
  display: flex;
  justify-content: space-between;
  margin: 15px;
}
.item_detail_title {
  font-size: 14px;
  font-weight: 600;
  color: rgb(148, 142, 142);
}
.item_detail_des {
  font-size: 12px;
}
.total_amt_title {
  font-size: 14px;
  font-weight: 600;
  color: rgb(148, 142, 142);
}
.total_amt_des {
  font-size: 12px;
  font-weight: 600;
}

@media screen and (max-width: 480px) {
  .my_order_title {
    text-align: center;
    margin-top: 40px;
  }
  .order_main_content {
    margin-top: -20px;
  }

  .order_card {
    margin: 11px;
    border-left: 10px solid #539a3f;
  }
  .order_number {
  }
  .order_card_content_1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 13px;
  }
  .order_card_content_2 {
    height: 15px;
  }
  .order_detail_hide {
    text-align: center;
    margin: -10px 35px;
    background-color: rgb(208, 212, 212);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .order_detail_hide_div {
    display: flex;
    justify-content: space-between;
    margin: 15px;
  }
  .item_detail_title {
    font-size: 14px;
    font-weight: 600;
    color: rgb(148, 142, 142);
  }
  .item_detail_des {
    font-size: 12px;
  }
  .total_amt_title {
    font-size: 14px;
    font-weight: 600;
    color: rgb(148, 142, 142);
  }
  .total_amt_des {
    font-size: 12px;
    font-weight: 600;
  }
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  
  .my_order_title {
    text-align: center;
    margin-top: 40px;
  }
  .order_main_content {
    margin-top: -20px;
  }

  .order_card {
    margin-left: 25px;
    margin-right: 25px;
    border-left: 10px solid #539a3f;
  }
  .order_number {
  }
  .order_card_content_1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 13px;
  }
  .order_card_content_2 {
    height: 15px;
  }
  .order_detail_hide {
    margin-left: 100px;
    margin-right: 100px;
    text-align: center;
    background-color: rgb(208, 212, 212);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .order_detail_hide_div {
    display: flex;
    justify-content: space-between;
    margin: 15px;
  }
  .item_detail_title {
    font-size: 14px;
    font-weight: 600;
    color: rgb(148, 142, 142);
  }
  .item_detail_des {
    font-size: 12px;
  }
  .total_amt_title {
    font-size: 14px;
    font-weight: 600;
    color: rgb(148, 142, 142);
  }
  .total_amt_des {
    font-size: 12px;
    font-weight: 600;
  }
}
