.order_title {
    text-align: center;
    margin: 40px;
  }

  .heading {
    font-size: 20px;
    font-weight: bold;
  }

  .invoice {
    font-size: 12px;
    font-weight: lighter;
  }
  .order-track {
    margin-top: 40px;
    margin-left: 830px;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  .order-track-step {
    display: flex;
    height: 8rem;
  }
  .order-track-status {
    margin-right: 1.5rem;
    position: relative;
  }
  .order-track-status-dot {
    display: block;
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 50%;
    background: #23a7df;
  }
  .order-track-status-line {
    display: block;
    margin: 0 auto;
    width: 2px;
    height: 7rem;
    background-color: #23a7df;
  }
  .order-track-text-stat {
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 3px;
  }
  .order-track-text-sub {
    font-size: 0.7rem;
    font-weight: 300;
  }
  .cooking_img {
    width: 180px;
    height: 180px;
    margin-left: 850px;
  }
  .order_notify {
    margin-left: 760px;
    margin-right: 830px;
    
  }
  .order_notify_text {
    font-size: 14px;
    margin-left: 13px;
  }
  .order_notify_input {
    width: 330px;
    height: 3rem;
    border-color: #23a7df;
    border-style: solid;
    border-radius: 10px;
  }
  .order_press {
    margin: 50px;
  }
  .order_press_text {
    font-size: 16px;
    color: rgb(123, 141, 158);
    text-align: center;
  }
  .horn_img {
    width: 100px;
    height: 100px;
    margin-top: 20px;
    margin-left: 820px;
    margin-bottom: 30px;
  }











@media screen and (max-width: 480px) {
  .order_title {
    text-align: center;
    margin: 40px;
  }

  .heading {
    font-size: 20px;
    font-weight: bold;
  }

  .invoice {
    font-size: 12px;
    font-weight: lighter;
  }
  .order-track {
    margin-top: 40px;
    margin-left: 70px;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  .order-track-step {
    display: flex;
    height: 8rem;
  }
  .order-track-status {
    margin-right: 1.5rem;
    position: relative;
  }
  .order-track-status-dot {
    display: block;
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 50%;
    background: #23a7df;
  }
  .order-track-status-line {
    display: block;
    margin: 0 auto;
    width: 2px;
    height: 7rem;
    background-color: #23a7df;
  }
  .order-track-text-stat {
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 3px;
  }
  .order-track-text-sub {
    font-size: 0.7rem;
    font-weight: 300;
  }
  .cooking_img {
    width: 180px;
    height: 180px;
    margin-left: 110px;
  }
  .order_notify {
    margin: 40px;
  }
  .order_notify_text {
    font-size: 14px;
    margin-left: 13px;
  }
  .order_notify_input {
    width: 330px;
    height: 3rem;
    border-color: #23a7df;
    border-style: solid;
    border-radius: 10px;
  }
  .order_press {
    margin: 50px;
  }
  .order_press_text {
    font-size: 16px;
    color: rgb(123, 141, 158);
    
  }
  .horn_img {
    width: 130px;
    height: 120px;
    margin-top: 20px;
    margin-left: 80px;
    margin-bottom: 20px;
  }
}


/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  
  .order_title {
    text-align: center;
    margin: 40px;
  }

  .heading {
    font-size: 20px;
    font-weight: bold;
  }

  .invoice {
    font-size: 12px;
    font-weight: lighter;
  }
  .order-track {
    margin-top: 60px;
    margin-left: 270px;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  .order-track-step {
    display: flex;
    height: 8rem;
  }
  .order-track-status {
    margin-right: 1.5rem;
    position: relative;
  }
  .order-track-status-dot {
    display: block;
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 50%;
    background: #23a7df;
  }
  .order-track-status-line {
    display: block;
    margin: 0 auto;
    width: 2px;
    height: 7rem;
    background-color: #23a7df;
  }
  .order-track-text-stat {
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 3px;
  }
  .order-track-text-sub {
    font-size: 0.7rem;
    font-weight: 300;
  }
  .cooking_img {
    width: 180px;
    height: 180px;
    margin-left: 270px;
  }
  .order_notify {
    margin-left: 270px;
  }
  .order_notify_text {
    font-size: 14px;
    margin-left: 13px;
    width: 260px;
  }
  .order_notify_input {
    width: 330px;
    height: 3rem;
    border-color: #23a7df;
    border-style: solid;
    border-radius: 10px;
  }
  .order_press {
    margin-left: 250px;
  }
  .order_press_text {
    font-size: 16px;
    color: rgb(123, 141, 158);
    width: 300px;
    
  }
  .horn_img {
    width: 130px;
    height: 120px;
    margin-top: 20px;
    margin-left: 80px;
    margin-bottom: 20px;
  }
  
}