.signin {
  background-color: #23a7df;
  color: white;
  height: 100vh;
}

.title {
  text-align: center;
}
.form {
  position: absolute;
  margin-top: 140px;
  margin-left: 43%;
}

@media screen and (max-width: 480px) {
  .signin {
    background-color: #23a7df;
    color: white;
    height: 100vh;
  }

  .title {
    text-align: center;
  }
  .form {
    position: absolute;
    margin-top: 160px;
    margin-left: 18%;
  }
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .signin {
    background-color: #23a7df;
    color: white;
    height: 100vh;
  }

  .title {
    text-align: center;
  }
  .form {
    position: absolute;
    margin-top: 230px;
    margin-left: 250px;
  }
}
