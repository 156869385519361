.logo {
  background-color: #177096;
  width: 100%;
  text-align: center;
  height: 300px;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
}
.profileimgdiv {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.profile {
  height: 100%;
  margin-top: 20px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  margin-left: 25%;
  margin-right: 25%;
}
.profileimg {
  height: 150px;
  width: 160px;
  object-fit: cover;

  border-radius: 30px;
}
.editIcon {
  position: absolute;
  margin-top: 125px;
  margin-left: 140px;
  border-radius: 10px;
  padding: 2px;
  width: 32px;
  height: 32px;
}
.profile_details {
  margin: 70px;
}
.savebtn {
  margin-left: 330px;
  margin-top: 40px;
  width: 180px;
}

@media screen and (max-width: 480px) {
  .logo {
    background-color: #177096;
    width: 100%;
    text-align: center;
    height: 250px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }
  .profile {
    height: 100%;
    margin: 20px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    border-radius: 20px;
  }
  .profileimg {
    height: 150px;
    width: 160px;
    object-fit: cover;
    margin-top: 40px;
    border-radius: 30px;
  }
  .editIcon {
    position: absolute;
    margin-top: 165px;
    border-radius: 10px;
    padding: 2px;
    width: 32px;
    height: 32px;
  }
  .profile_details {
    margin: 20px;
  }
  .savebtn {
    margin-left: 80px;
    margin-bottom: 10px;
    width: 180px;
  }
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .logo {
    background-color: #177096;
    width: 100%;
    text-align: center;
    height: 250px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }
  .profile {
    height: 100%;
    margin: 40px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    border-radius: 20px;
  }
  .profileimg {
    height: 150px;
    width: 160px;
    object-fit: cover;
    margin-top: 40px;
    border-radius: 30px;
  }
  .editIcon {
    position: absolute;
    margin-top: 165px;
    border-radius: 10px;
    padding: 2px;
    width: 32px;
    height: 32px;
  }
  .profile_details {
    margin: 20px;
  }
  .savebtn {
    margin-left: 250px;
    margin-bottom: 10px;
    width: 180px;
  }
}
