.CartBg {
  background-color: #23a7df;
  height: 100vh;
}
.CartProfile {
  background-color: #e6e6e6;
  height: 300px;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
}
.avatar {
  margin-left: 800px;
  margin-top: 110px;
  position: absolute;
}
.mycartItem {
  position: absolute;
  width: 500px;
  margin-top: 40px;
  margin-left: 580px;
}
.btn1 {
  border: 1px;
  color: white;
  background-color: #23a7df;
  margin-bottom: 10px;
  border-color: white;
  border-style: solid;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  width: 38px;
}
.btn2 {
  border: 1px;
  color: #23a7df;
  background-color: white;
  width: 38px;
  margin-bottom: 10px;
  border-color: white;
  border-style: solid;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}
.itemtext {
  border: 1px;
  color: white;
  background-color: #23a7df;
  margin-bottom: 8px;
  border-color: white;
  border-style: solid;
  margin-left: 20px;
  width: 50px;
  border-radius: 15px;
  text-align: center;
}
.mycarttitle {
  color: white;
  font-size: 22px;
  margin-bottom: 20px;
}
.itemAmt {
  margin-left: 290px;
}

@media screen and (max-width: 480px) {
  .CartBg {
    background-color: #23a7df;
    height: 100vh;
  }
  .CartProfile {
    background-color: #e6e6e6;
    height: 300px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }
  .avatar {
    margin-left: 130px;
    position: absolute;
    margin-top: 120px;
  }
  .mycartItem {
    position: absolute;
    width: 381px;
    margin-top: 40px;
    margin-left: 13px;
  }
  .btn1 {
    border: 1px;
    color: white;
    background-color: #23a7df;
    margin-bottom: 10px;
    border-color: white;
    border-style: solid;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    width: 38px;
  }
  .btn2 {
    border: 1px;
    color: #23a7df;
    background-color: white;
    width: 38px;
    margin-bottom: 10px;
    border-color: white;
    border-style: solid;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  .itemtext {
    border: 1px;
    color: white;
    background-color: #23a7df;
    margin-bottom: 8px;
    border-color: white;
    border-style: solid;
    margin-left: 20px;
    width: 50px;
    border-radius: 15px;
    text-align: center;
  }
  .mycarttitle {
    color: white;
    font-size: 22px;
    margin-bottom: 20px;
  }
  .itemAmt {
    margin-left: 180px;
  }
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .CartBg {
    background-color: #23a7df;
    height: 100vh;
  }
  .CartProfile {
    background-color: #e6e6e6;
    height: 300px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }
  .avatar {
    margin-left: 300px;
    position: absolute;
    margin-top: 120px;
  }
  .mycartItem {
    position: absolute;
    margin-top: 40px;
    margin-left: 130px;
  }
  .btn1 {
    border: 1px;
    color: white;
    background-color: #23a7df;
    margin-bottom: 10px;
    border-color: white;
    border-style: solid;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    width: 38px;
  }
  .btn2 {
    border: 1px;
    color: #23a7df;
    background-color: white;
    width: 38px;
    margin-bottom: 10px;
    border-color: white;
    border-style: solid;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  .itemtext {
    border: 1px;
    color: white;
    background-color: #23a7df;
    margin-bottom: 8px;
    border-color: white;
    border-style: solid;
    margin-left: 20px;
    width: 50px;
    border-radius: 15px;
    text-align: center;
  }
  .mycarttitle {
    color: white;
    font-size: 22px;
    margin-bottom: 20px;
  }
  .itemAmt {
    margin-left: 300px;
  }
}
